#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
}

.logo {
  height: 32px;
  margin: 16px 0px 30px 32px;
  background: #5631de;
}

.logo-titulo {
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-sider-trigger {
  background-color: #5631de !important;
}
.ant-menu .ant-menu-sub .ant-menu-inline {
  background-color: #5631de !important;
}

.ant-layout-header .site-layout-background {
  background: #5631de !important;
}
.nombre-perfil{
  padding-right: 10px;
  margin-right: 10px;
}
.nombre-perfil h5 {
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
