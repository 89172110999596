.container{
    background-color: #ffffff;
    border: 0.5px solid #353333;
    position: absolute;
    left: 50%;
    margin-top: 10px;
    margin-right: 15px;
    max-width: 30%;
    min-width: 300px;
    padding: 40px 40px 10px 40px;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 7px 4px 10px 0px rgb(71, 71, 71);
}
.login-container{    
    text-align: center;    
}
.back-login{
    background-color: #5631DE;
    height: 100%;
}
#root{
    height: 100%;
}